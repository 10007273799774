import React,{useEffect} from "react";
import AOS from "aos";


const Services = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div id="services" className="text-center container-fluid">
      <div className="container">
        <h1 className="pb-4">Our Services</h1>
        <div className="row m-auto justify-content-center align-items-center">
          <div
            className="col-12 col-sm-6"
            data-aos="fade-right"
            data-aos-duration="500"
          >
            <h6>Authorized Service Centers</h6>
            <h6>Technical Expertise - Routine Maintenance</h6>
            <h6>Diagnosis and Troubleshooting - Repairs</h6>
            <h6>Warranty Coverage - Service Contract</h6>
          </div>
          <div
            className="col-12 col-sm-6"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <img
              src="./images/services/service.jpg"
              alt="Ricoma Embroidery"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-sm-6"></div>
        </div>
      </div>
    </div>
  );
};

export default Services;
