import React from 'react'
import HeadTitle from "../HeadTitle/HeadTitle";
import Card from "./Card";

import productData from "./productData";

const Products = () => {

  
  
  return (
    <section className="products ">
      <HeadTitle />

      <h2 className="text-center mb-5">Products</h2>
      <div className="container">
        <div className="row">
          {productData.map((product)=>{
            return (
              <div className="col-6 col-md-3 col-lg-3" key={product.id}>
                <Card images={product.images} title={product.title} navlink={product.navlink} desc={product.desc}/>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  );
};

export default Products;
