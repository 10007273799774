import {useState, createContext } from 'react';
import ProductData from './productData';

export const EmbroideryContext = createContext();

const EmbroideryProvider = ({ children }) => {
const [products,setProducts]= useState(ProductData);



  return <EmbroideryContext.Provider value = {products}>{children}</EmbroideryContext.Provider>;
};

export default EmbroideryProvider;