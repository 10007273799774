
// import Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Whatsapp from "./common/Whatsapp/Whatsapp";


import Navbar from "./common/NavbarContainer/Navbar";
import Home from "./HomePages/Home";  

import About from "./component/AboutContainer/About";

import Service from "./component/Service/Service";
import Software from "./component/DigitizingSoftware/Software";

import Footer from "./common/Footer/Footer";

// ScrollToTop
import ScorllToTop from "./common/ScorllToTop/ScorllToTop";
import Privacy from "./component/Privacy-policy/Privacy";
import TermAndCondition from "./component/Privacy-policy/TermAndCondition";
import ErrorPage from "./component/Errorpage/ErrorPage";
import News from "./component/NewsPage/News";

//Products
import Products from './common/Products/Products'
import Contact from "./HomePages/ContactForm/Contact";
import Embroidery from "./common/Products/Embroidery";
import SinglePage from "./common/SinglePage";
import Decorating from "./component/DigitizingSoftware/Decorating";


const App = () => {
  
  
  return (
    <Router>
      <ScorllToTop />
      <Whatsapp />

      <Navbar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="products" element={<Products />}></Route>
        <Route path="embroidery" element={<Embroidery />}></Route>
        <Route path=":navlink" element={<SinglePage />} />

        <Route path="/software" element={<Software />} />
        <Route path="/Decorating" element={<Decorating />} />
        <Route path="/service" element={<Service />} />
        <Route path="/news" element={<News />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermAndCondition />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
