import React from 'react'
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
const navigate = useNavigate()

const errorStyle = {
  display:"flex",
  flexDirection:"column",
  justifyContent:'center',
  alignItems:'center',
  height:"100vh"


}

    const handleClick = ()=>{
       navigate("/");
    }
  return (
    <div className="error container p-4 text-center" style={errorStyle}>
      <img src="./images/error-page.jpg" alt="" className="img-fluid" />
      <h2 className='error__title'>  Oops!</h2>
      <p className='lead error__desc'>
        We can't find the pag you're <br /> looking for.
      </p>
      <button type='button' className='btn btn-danger error__btn' onClick={() => handleClick()}>
        Back to Home
      </button>
    </div>
  );
}

export default ErrorPage