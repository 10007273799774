const productData = [
  {
    id: 1,
    navlink: "pv_series",
    title: "pv_series",

    images: "images/products/1-pv_series.jpg",
    desc: "Single head - PV series",
  },
  {
    id: 2,
    navlink: "em_series",
    title: "em_series",
    images: "images/products/2-em-series.jpg",
    desc: "All Embroidery Machine Service and AMC",
  },
  {
    id: 3,
    navlink: "TC_7S_series",
    title: "TC_7S_series",
    images: "images/products/3-TC_7S_series.jpg",
    desc: "Wilcom Latest Advanced Technical Software",
  },
  {
    id: 4,
    navlink: "TC_8S_series",
    title: "TC_8S_series",
    images: "images/products/4-TC_8S_series.jpg",
    desc: "FullStack Web Develop and Production",
  },
  {
    id: 5,
    navlink: "swd_series",
    title: "swd_series",
    images: "images/products/5-SWD_series.jpg",
    desc: "Single head - PV series",
  },
  {
    id: 6,
    navlink: "MT_7S_series",
    title: "MT_7S_series",
    images: "images/products/6-MT_7S_series.jpg",
    desc: "All Embroidery Machine Service and AMC",
  },
  {
    id: 7,
    navlink: "MT_8S_series",
    title: "MT_8S_series",
    images: "images/products/7-MT-8S_series.jpg",
    desc: "Wilcom Latest Advanced Technical Software",
  },
  {
    id: 8,
    navlink: "CHT2_series",
    title: "CHT2_series",
    images: "images/products/8-CHT2_series.jpg",
    desc: "FullStack Web Develop and Production",
  },
  {
    id: 9,
    navlink: "CHT_series",
    title: "CHT_series",
    images: "images/products/9-CHT_series.jpg",
    desc: "Wilcom Latest Advanced Technical Software",
  },
  {
    id: 10,
    navlink: "CHCS_2004",
    title: "CHCS_2004",
    images: "images/products/10-CHCS_2004.jpg",
    desc: "FullStack Web Develop and Production",
  },
  {
    id: 11,
    navlink: "F_series",
    title: "F_series",
    images: "images/products/11-F_series.jpg",
    desc: "FullStack Web Develop and Production",
  },
  {
    id: 12,
    navlink: "MCC_061201",
    title: "MCC_061201",
    images: "images/products/12-MCC_061201.jpg",
    desc: "Wilcom Latest Advanced Technical Software",
  },
  {
    id: 13,
    navlink: "MFC_series",
    title: "MFC_series",
    images: "images/products/13-MFC_series.jpg",
    desc: "FullStack Web Develop and Production",
  },
];

export default productData;
