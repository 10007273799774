import React, { useEffect } from "react";
import './contact.css'
import CallIcon from "@mui/icons-material/Call";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import BusinessIcon from "@mui/icons-material/Business";
import { Link } from "react-router-dom";
import AOS from 'aos'

const Contact = () => {

  useEffect(()=>{
    AOS.init()
  })
  return (
    <div className="contact container pt-4 pb-4">
      <h2 className="title pb-4">Contact Us</h2>
      <div className="row gy-3">
        <div
          className="col-12 col-sm-12 col-md-6 col-lg-6 "
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          <img
            src="./images/contact.jpg"
            alt="Ricoma QATAR Embroidery Machine Sales And Services"
            className="img-fluid"
          />
        </div>
        <div
          className=" col-12 col-sm-12 col-md-6 col-lg-6 contact__desc"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <div className="mobile">
            <CallIcon />
            <Link to="tel:+97450907170" className="mobile-no">
              +974 5090 7170
            </Link>
          </div>
          <div className="mail">
            <AttachEmailIcon />
            <Link to="mailto:info@ricomaqatar.com" className="mail-add">
              info@ricomaqatar.com
            </Link>
          </div>
          <div className="address">
            <BusinessIcon />
            <div className="address-desc">Al Ruwais, Doha, Qatar.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
