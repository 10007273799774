import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <>
      <div className="page-header bg-color py-5">
        <div class="container">
          <div class="row align-items-center">
            <div class="col">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="!#">Home</a>
                  </li>
                  <li class="breadcrumb-item">
                    <a href="!#">Privacy Policy</a>
                  </li>
                </ol>
              </nav>
              <h1 class="text-white text-uppercase">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container privacy mt-3">
        <div className="row">
          <div className="col-">
            <p>
              RicomaQatar Embroidery Solutions is an authorized distributor for
              Ricoma Embroidery Machine.
            </p>
            <p>
              This Privacy Policy governs the manner in which RicomaQatar
              Embroidery Solutions collects, uses, maintains and discloses
              information collected from users (each, a “User”) of the
              www.ricomaqatar.com website (“Site”). This privacy policy applies
              to the Site and all products and services offered by the
              Ricomaqatar Embroidery Solutions.
            </p>
            <h3>Personal Identification Information:</h3>
            <p>
              We will collect personal identification information from Users
              only if they voluntarily submit such information to us. We may
              collect personal identification information from Users in a
              variety of ways, including, but not limited to, when Users visit
              our Site, register on the Site, place an order, subscribe to the
              newsletter, fill out a form, and in connection with other
              activities, services, features or resources we make available on
              our Site. Users may be asked for, as appropriate, name, email
              address, mailing address, phone number, credit card information.
              We will collect personal identification information from Users
              only if they voluntarily submit such information to us. Users can
              always refuse to supply personally identification information,
              except that it may prevent them from engaging in certain Site
              related activities.
            </p>
            <h3>Non-personal Identificaton:</h3>
            <p>
              We may collect non-personal identification information about Users
              whenever they interact with our Site. Non-personal identification
              information may include the browser name, the type of computer and
              technical information about Users, such as the operating system
              and the Internet service providers utilized and other similar
              information.
            </p>
            <h3>Web Browser Cookies:</h3>
            <p>
              Our Site may use “cookies” to enhance User experience. A User’s
              web browser places cookies on their hard drive for record-keeping
              purposes and sometimes to track information about them. A User may
              choose to set their web browser to refuse cookies, or to alert you
              when cookies are being sent. If they do so, note that some parts
              of the Site may not function properly.
            </p>
            <h3> How we use collected information:</h3>
            <p>
              RicomaQatar Embroidery Solutions may collect and use Users
              personal information for the following purposes:
            </p>
            <ul>
              <li>
                To improve customer service: Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </li>
              <li>
                To personalise user experience: We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </li>
              <li>
                To improve our Site: We may use feedback you provide to improve
                our products and services.
              </li>
              <li>
                To process payments: We may use the information Users provide
                about themselves when placing an order only to provide service
                to that order. We do not share this information with outside
                parties except to the extent necessary to provide the service.
              </li>
              <li>
                To run a promotion, contest, survey or other Site feature: To
                send Users information they agreed to receive about topics we
                think will be of interest to them.
              </li>
              <li>
                To send periodic emails: We may use the email address to send a
                User information and updates pertaining to their order. It may
                also be used to respond to their inquiries, questions, and/or
                other requests. If a User decides to opt-in to our mailing list,
                they will receive emails that may include company news, updates,
                related product or service information, etc. If at any time the
                User would like to unsubscribe from receiving future emails, we
                include detailed unsubscribe instructions at the bottom of each
                email.
              </li>
            </ul>
            <h3>How We Protect Your Information:</h3>
            <p>
              We adopt appropriate data collection, storage and processing
              practices and security measures to protect against unauthorized
              access, alteration, disclosure, or destruction of your personal
              information, username, password, transaction information and data
              stored on our Site.
            </p>
            <p>
              Sensitive and private data exchange between the Site and its Users
              happens over an SSL secured communication channel and is encrypted
              and protected with digital signatures.
            </p>
            <h3>Sharing your personal information:</h3>
            <p>
              We do not sell, trade, or rent Users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above.
            </p>
            <h3>Third Party Websites:</h3>
            <p>
              Users may find advertising or other content on our Site that link
              to the sites and services of our partners, suppliers, advertisers,
              sponsors, licensors and other third parties. We do not control the
              content or links that appear on these sites and are not
              responsible for the practices employed by websites linked to or
              from our Site. In addition, these sites or services, including
              their content and links, may be constantly changing. These sites
              and services may have their own privacy policies and customer
              service policies. Browsing and interaction on any other website,
              including websites that have a link to our Site, is subject to
              that website’s own terms and policies.
            </p>
            <h3>Changes To This Privacy Policy:</h3>
            <p>
              RicomaQatar Embroidery Solutions has the discretion to update this
              privacy policy at any time. When we do, we will revise the updated
              date at the bottom of this page. We encourage Users to frequently
              check this page for any changes to stay informed about how we are
              helping to protect the personal information we collect. You
              acknowledge and agree that it is your responsibility to review
              this privacy policy periodically and become aware of
              modifications.
            </p>
            <h3>GDPR Compliance:</h3>
            <p>
              RicomaQatar Embroidery Solutions has a GDPR Compliance Statement
              on <b>www.ricomaqatar.com</b> which has been compiled in the
              interest of our customers.
            </p>
            <h3>Your Acceptance Of These Terms</h3>
            <p>
              By using this Site, you consent your acceptance of this policy. If
              you do not agree to this policy, please do not use our Site. Your
              continued use of the Site following the posting of changes to this
              policy will be deemed your acceptance of those changes.
            </p>
            <h3>Contacting Us:</h3>
            <p>
              If you have any questions about this Privacy Policy, the practices
              of this site, you dealings with this site, or wish to obtain a
              copy of your personal information, please{" "}
              <a
                href="https://ricomaqatar.com"
                rel="noreferrer"
                target="_blank"
              >
                contact us
              </a>{" "}
              at:
            </p>
            <p>
              <br />
              RicomaQatar Embroidery Solutions <br /> Attn: Commnications Dept{" "}
              <br /> Building No.12, AlRuwais,
              <br /> AlShamal,Doha,Qatar.
            </p>
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
