import React,{useContext} from "react";
import HeadTitle from "./HeadTitle/HeadTitle";
import { useParams } from "react-router-dom";
import { EmbroideryContext } from "../Context/EmbroideryContext";


const SinglePage = () => {
  const productId = useParams()

 const EmbroideryData = useContext(EmbroideryContext);
 console.log(EmbroideryData);

 const productItem = EmbroideryData.find(item=>item.navlink === productId.navlink);

 const {title, desc, images,navlink,id}= productItem
 console.log(productItem)

 

  return (
    <div>
      <HeadTitle />
      <h2 className="text-center mb-5">Products</h2>
      <h1>{title}</h1>
      <img src={images} alt={title} style={{width:"auto",height:"500px",display:'flex',justifyContent:'center',alignItems:'center',margin:'auto', padding:'20px'}} />
      <p>{desc}</p>
    </div>
  );
};

export default SinglePage;
