const productData = [
  {
    id: 1,
    title: "Embroidery",
    images: "images/products/embroidery.jpg",
    navlink:"embroidery",
    desc:"Single head and Multi head Embroidery Machine"
  },
  {
    id: 2,
    title: "Services",
    images: "images/products/services.jpg",
    navlink:"service",
    desc:"All Embroidery Machine Service and AMC"
  },
  {
    id: 3,
    title: "Software",
    images: "images/products/software.jpeg",
    navlink:'software',
    desc:"Embroidery Designing Latest Advanced Technology Software"
  },
  {
    id: 4,
    title: "Web Develop",
    images: "images/products/webDev.jpg",
    navlink:'webdev',
    desc:"FullStack Web Develop and Production"
  },
];

export default productData;
