import React from "react";
import {Link} from 'react-router-dom'
import Social from "./Social";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import "./Navbar.css";

const Topbar = () => {
  return (
    <div className="topbar d-none d-sm-block ">
      <div className="container">
        <div className="row m-auto">
          <div className="col-md-6 col-sm-6 d-flex justify-content-start topbar__phone">
            <Link to="tel:+97450907170">
              <PhoneInTalkIcon sx={{ marginRight: "5px" }} />
              <span className="topbar__phone--tel">+974 5090 7170</span>
            </Link>
          </div>
          <div className="col-md-6 col-sm-6 col-6 topbar__social">
            <Social />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
