import React from 'react'

const TermAndCondition = () => {
  return (
    <div className="term-and-condition container pt-5 pb-5">
      <div className="term-header text-center pb-4">
        <h2>Terms of Service</h2>
        <p>Ricoma’s Terms of Service was updated on May 24, 2018.</p>
      </div>
      <hr />
      <p>
        Ricoma operates each website ('Site') that links to these Terms of Use
        to provide online access to information about Ricoma and the products,
        services, and opportunities we provide.
      </p>
      <p>By accessing and using the Site, you agree to these Terms of Use.</p>
      <p>
        Ricoma reserves the right to modify these Terms of Use at any time
        without giving you prior notice. Your use of the Site following any such
        modification constitutes your agreement to follow and be bound by these
        Terms of Use as modified. The last date these Terms of Use were revised
        is set forth below.
      </p>
      <h2 className='pt-3'>1. Permitted Use of The Site</h2>
      <p>
        You may use the Site, and the information, writings, images and/or other
        works that you see, hear or otherwise experience on the Site (singly or
        collectively, the 'Content') solely for your non-commercial, personal
        purposes and/or to learn about Ricoma products and services, and solely
        in compliance with these Terms of Use.
      </p>
      <h2>2. Prohibited Use of The Site</h2>
      <p>By accessing the Site, you agree that you will not :</p>
      <ol>
        <li>Use the Site in violation of these Terms of Use;</li>
        <li>
          Copy, modify, create a derivative work from, reverse engineer or
          reverse assemble the Site, or otherwise attempt to discover any source
          code, or allow any third party to do so;
        </li>
        <li>
          Sell, assign, sublicense, distribute, commercially exploit, grant a
          security interest in or otherwise transfer any right in, or make
          available to a third party, the Content or Service in any way;
        </li>
        <li>
          Use or launch any automated system, including without limitation,
          "robots," "spiders," or "offline readers," that accesses the Site in a
          manner that sends more request messages to the Ricoma servers in a
          given period of time than a human can reasonably produce in the same
          period by using a conventional on-line web browser;
        </li>
        <li>
          Use the Site in any manner that damages, disables, overburdens, or
          impairs any Ricoma website or interferes with any other party's use
          and enjoyment of the Site;
        </li>
        <li>
          Mirror or frame the Site or any part of it on any other web site or
          web page.
        </li>
        <li>Attempt to gain unauthorized access to the Site;</li>
        <li>
          Access the Site by any means other than through the interface that is
          provided by Ricoma for use in accessing the Site;
        </li>
        <li>
          Use the Site for any purpose or in any manner that is unlawful or
          prohibited by this Agreement.
        </li>
        <li>
          Any unauthorized use of any Content or the Site may violate patent,
          copyright, trademark, and other laws.
        </li>
      </ol>
      <h2>3. Copyrights and Trademarks</h2>{" "}
      <p>
        The Site is based upon proprietary Ricoma technology and includes the
        Content. The Site is protected by applicable intellectual property and
        other laws, including trademark and copyright laws. The Site, including
        all intellectual property rights in the Site, belongs to and is the
        property of Ricoma or its licensors (if any). Ricoma owns and retains
        all copyrights in the Content. Except as specifically permitted on the
        Site as to certain Content, the Content may not be copied, reproduced,
        modified, published, uploaded, posted, transmitted, performed, or
        distributed in any way, and you agree not to modify, rent, lease, loan,
        sell, distribute, transmit, broadcast, or create derivative works based
        on the Content or the Site, in whole or in part, by any means. Ricoma
        logos and other marks used by Ricoma from time to time are trademarks
        and the property of Ricoma. The appearance, layout, color scheme, and
        design of the Ricoma Websites are protected trade dress. Customers do
        not receive any right or license to use the foregoing.Ricoma may use and
        incorporate into the Site or the Ricoma Service any suggestions or other
        feedback you provide, without payment or condition. Pursuant to Title
        17, United States Code, Section 512(c)(2), notifications of claimed
        copyright infringement on the Site or the Service should be sent to
        Ricoma’s designated Copyright Agent. See the Claims of Copyright
        Infringement instructions below.
      </p>
      <h2>4. Links to Third-Party Web Sites</h2>
      <p>
        Links on the Site to third party web sites or information are provided
        solely as a convenience to you. If you use these links, you will leave
        the Site. Such links do not constitute or imply an endorsement,
        sponsorship, or recommendation by Ricoma of the third party, the
        third-party web site, or the information there. Ricoma is not
        responsible for the availability of any such web sites. Ricoma is not
        responsible or liable for any such web sites or the content thereon. If
        you use the links to the web sites of Ricoma affiliates or service
        providers, you will leave the Site and will be subject to the terms of
        use and privacy policy applicable to those web sites.
      </p>
      <h2>5. Downloading Files</h2>{" "}
      <p>
        Ricoma cannot and does not guarantee or warrant that files available for
        downloading through the Site will be free of infection by software
        viruses or other harmful computer code, files or programs.
      </p>
      <h2>6. Disclaimers; Limitations of Liability</h2>{" "}
      <p>
        RICOMA AND ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS MAKE NO
        REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
        TIMELINESS, SECURITY OR ACCURACY OF THE SITE OR THE CONTENT FOR ANY
        PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
        INFORMATION, SOFTWARE, PRODUCTS, SERVICE AND RELATED GRAPHICS ARE
        PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. RICOMA AND
        ITS SERVICE PROVIDERS, LICENSORS AND SUPPLIERS HEREBY DISCLAIM ALL
        WARRANTIES AND CONDITIONS OF ANY KIND WITH REGARD TO THE SITE AND THE
        CONTENT, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
        NON-INFRINGEMENT. NO STATEMENT OR INFORMATION, WHETHER ORAL OR WRITTEN,
        OBTAINED FROM RICOMA IN ANY MEANS OR FASHION SHALL CREATE ANY WARRANTY
        NOT EXPRESSLY AND EXPLICITLY SET FORTH IN THIS AGREEMENT. THE CONTENT
        MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL RICOMA AND ITS SERVICE
        PROVIDERS, LICENSORS OR SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
        PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR OTHER TYPE OF
        DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR COVER OR LOSS OF USE,
        DATA, REVENUE OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
        THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE
        THE SITE, OR FOR ANY CONTENT, OR OTHERWISE ARISING OUT OF THE USE OF THE
        SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, THE
        FAILURE OF ANY LIMITED REMEDY TO ACHIEVE ITS ESSENTIAL PURPOSE, OR
        OTHERWISE, EVEN IF RICOMA OR ANY OF RICOMA’S SUPPLIERS HAS BEEN ADVISED
        OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT
        ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
        INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF,
        NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT, RICOMA IS DETERMINED
        TO HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY FOR ANY LOSS, HARM OR
        DAMAGE, YOU AGREE THAT THE AGGREGATE LIABILITY OF RICOMA AND ITS
        OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AFFILIATES, AGENTS,
        CONTRACTORS, SERVICE PROVIDERS, LICENSORS OR SUPPLIERS SHALL IN ALL
        CASES BE LIMITED TO ONE HUNDRED DOLLARS.
      </p>
      <h2>7. Indemnification</h2>
      <p>
        You understand and agree that you are personally responsible for your
        behavior on the Site. You agree to indemnify, defend and hold harmless
        Ricoma, its parent companies, subsidiaries, affiliated companies, joint
        venturers, business partners, licensors, employees, agents, and any
        third-party information providers from and against all claims, losses,
        expenses, damages and costs (including, but not limited to, direct,
        incidental, consequential, exemplary and indirect damages), and
        reasonable attorneys' fees, resulting from or arising out of your use,
        misuse, or inability to use the Site or the Content, or any violation by
        you of these Terms of Use.
      </p>
      <h2>8. Privacy</h2>
      <p>Your use of the Site is subject to Ricoma’s Privacy Policy</p>
      <h2>9. General Provisions</h2>
      <p>
        a. Entire Agreement/No Waiver. These Terms of Use constitute the entire
        agreement of the parties with respect to the subject matter hereof. No
        waiver by Ricoma of any breach or default hereunder shall be deemed to
        be a waiver of any preceding or subsequent breach or default. b.
        Correction of Errors and Inaccuracies. The Content may contain
        typographical errors or other errors or inaccuracies and may not be
        complete or current. Ricoma therefore reserves the right to correct any
        errors, inaccuracies or omissions and to change or update the content at
        any time without prior notice. Ricoma does not, however, guarantee that
        any errors, inaccuracies or omissions will be corrected.
      </p>
      <h2>10. Income Disclaimer</h2>
      <p>
        Ricoma International Corporation cannot guarantee the results that each
        individual experiences with our ideas, techniques, tools or strategies.
        We cannot assure that you will earn money or grow your business with our
        products, ideas or content. However, we can assure that the techniques
        we share have worked for us or others. Any income expectancies or
        financial numbers referenced in our content is for educational purposes
        and to provide a general idea of what is working for others in the
        industry. This does not mean that you will reach these numbers just by
        watching our content and/or purchasing our equipment.
      </p>
      <h2>11. Live Online Events Notice</h2>
      <p>
        In the case of a webinar, an onsite event or training session, the
        event, event time, speakers and other event information is subject to
        change without notice. However, if time and circumstances permit, you
        may be notified via email if a speaker, trainer or live webinar time
        changes.
      </p>
    </div>
  );
}

export default TermAndCondition