import React, { useState } from "react";
import {Link} from 'react-router-dom'
import "./Navbar.css";
import Topbar from "./Topbar";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(true);
  };

  


  return (
    <>
      <Topbar />
      <nav className="navbar navbar-expand-md sticky-top">
        <div className="container-fluid">
          <div className="navbar-brand px-5">
            <Link to="/">
              <img
                src="/images/ricoma-logo.png"
                alt="Ricoma Qatar Embroidery Machine"
                className="img-responsive"
              />
            </Link>
          </div>
          <></>

          <button
            type="button"
            className="navbar-toggler"
            data-bs-target="#navbarSupportedContent"
            data-bs-toggle={toggle ? "collapse" : ""}
            onClick={handleClick}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="navbar-collapse collapse bg-light text-center rounded-3"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto px-5">
              <li className="nav-item px-1 active">
                <Link
                  to="/"
                  className="nav-link"
                 
                >
                  Home
                </Link>
              </li>
              <li className="nav-item px-1">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
              <li className="nav-item px-1">
                <Link to="/products" className="nav-link">
                  Products
                </Link>
              </li>

              <li className="nav-item px-1">
                <Link to="/service" className="nav-link">
                  Service
                </Link>
              </li>
              <li className="nav-item px-1">
                <Link to="/software" className="nav-link">
                  Software
                </Link>
              </li>
              <li className="nav-item px-1">
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
