import React from "react";
import "./hero.css";

const Hero = () => {
  return (
    <>
      <div id="hero-carousel" className="carousel slide">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#hero-carousel"
            className="active"
            aria-current="true"
            data-bs-slide-to="0"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#hero-carousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        </div>

        <div className="carousel-inner">
          {/* 1-slide */}
          <div className="carousel-item active c-item">
            <div className="carousel-caption d-md-block text-center">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-6 col-sm-6 mt-5 hero-left container mx-auto">
                    <h3 className="animate__animated animate__backInLeft hero-exclusive text-justify">
                      New and Used Embroidery Machines, Parts, Service.
                    </h3>

                    <div className="hero-exclusive">
                      <h2 className="animate__animated animate__fadeInUp animate__delay-1s">
                        Exclusive Distributor
                      </h2>

                      <img
                        src="./images/Hero/ricoma-logo.png"
                        alt="Ricoma Embroidery Machine"
                        className="c-caption-img img-fluid animate__animated animate__backInUp"
                      />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 container">
                    <img
                      src="./images/Hero/SWDHead.png"
                      alt="Ricoma Embroidery Machine"
                      className="img-fluid animate__animated animate__backInRight animate__delay-1s my-auto d-block hero-machine"
                    />
                  </div>
                </div>
              </div>

              <a
                className="btn btn-primary mt-2 animate__animated animate__flipInY animate__delay-1s"
                type="button"
                href="/products"
              >
                More!
              </a>
            </div>
          </div>

          {/* 2-slide */}
          <div className="carousel-item c-item">
            <div className="carousel-caption d-md-block text-center">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-6 col-sm-6 mt-5 hero-left container mx-auto">
                    <h3 className="animate__animated animate__backInLeft hero-exclusive text-justify">
                      New and Used Embroidery Machines, Parts, Service.
                    </h3>

                    <div className="hero-exclusive">
                      <h2 className="animate__animated animate__fadeInUp animate__delay-1s">
                        Exclusive Distributor
                      </h2>

                      <img
                        src="./images/Hero/ricoma-logo.png"
                        alt="ricoma"
                        className="c-caption-img img-fluid animate__animated animate__backInUp"
                      />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 container">
                    <img
                      src="./images/Hero/MT-2Head.png"
                      alt="2Head MT series"
                      className="img-fluid animate__animated animate__backInRight animate__delay-1s my-auto d-block hero-machine"
                    />
                  </div>
                </div>
              </div>

              <a
                className="btn btn-primary mt-2 animate__animated animate__flipInY animate__delay-1s"
                type="button"
                href="/products"
              >
                More!
              </a>
            </div>
          </div>
        </div>

        {/* Arrow */}
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#hero-carousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
};

export default Hero;
