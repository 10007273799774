import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {Link} from 'react-router-dom'

const Social = () => {
  return (
    <>
      <Link to="https://www.facebook.com/ricomaqatar/">
        <FacebookIcon sx={{ color: "#fff" }} />
      </Link>
      <Link to="https://www.instagram.com/ricoma.qatar/">
        <InstagramIcon sx={{ color: "#fff" }} />
      </Link>
      <Link to="https://api.whatsapp.com/send/?phone=97450907170&text=I%27m+interested+in+your+Embroidery+Machine+for+Buying+Send+Details&type=phone_number&app_absent=0">
        <WhatsAppIcon sx={{ fill: "#fff" }} />
      </Link>
      <Link to="https://www.youtube.com/@ricomaQatar">
        <YouTubeIcon sx={{ color: "#fff" }} />
      </Link>
    </>
  );
};

export default Social;
