import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Animate.css
import "animate.css";
// AOS
import "aos/dist/aos.css";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmbroideryProvider from "./Context/EmbroideryContext";
// Swiper Carousel

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <EmbroideryProvider>

    <React.StrictMode>
      <App />
    </React.StrictMode>
  </EmbroideryProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
