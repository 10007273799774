import React from "react";
import "./About.css";
import HeadTitle from "../../common/HeadTitle/HeadTitle";

const About = () => {
  return (
    <>
      <HeadTitle />
      <section className="about text-center">
        <div className="about__company mb-3 container">
          <h2 className="about__title">Company Profile</h2>
          <p className="about__company-desc">
            We are headquartered in Miami, USA and own a production facility in
            Huizhou city, a marketing center in Shenzhen city, China.
            <br />
            RiCOMA is a multinational high-tech enterprise engaged in R&D,
            manufacturing, sales and service of garment decoration equipment and
            software. At RiCOMA, we have “A Passion for Excellence" in
            productivity, up-to-date technology and reliability, which makes
            RiCOMA the most preferred source for embroidery equipment and
            digitizing software.
            <br /> Through years of relentless efforts, we have developed our
            own core technologies, established a comprehensive production base
            and built up a highly efficient sales and service network worldwide.
            <br />
            Today, RiCOMA is a well-known brand in the garment decoration
            industry throughout the world. Our factory is the most advanced
            state-of-the-art production with the spacious plant that is operated
            very efficiently with a modern and beautiful environment.
            <br /> We have a professional staff and well-trained work force that
            reinforces RiCOMA’s unique and outstanding corporate culture. With
            customers’ needs in mind at all times, RiCOMA has been adhering to
            independent technological innovation and implementing brand
            marketing strategy. For many years, we have been committed to
            research, development, design and production of computerized
            embroidery machines and software applications. Our research and
            development team has established strategic alliance and cooperation
            with partners in the United States, Britain, Germany, Japan, South
            Korea and many other countries.
            <br /> With the establishment of a strict and comprehensive quality
            management and control system, each product undergoes a rigorous
            inspection and testing process. RiCOMA people always adhere to the
            most stringent inspection and quality control throughout the
            purchase of raw materials, semi-finished products processing,
            production and product packaging.
            <br /> This whole process has guaranteed the high quality of RiCOMA
            products. All our products have been approved with international
            certification, such as CE and RoHS. Equipped with a strong
            professional and international sales and service team, RiCOMA has
            established distributing networks in more than 60 countries and
            regions, which has further helped thousands of customers succeed in
            their business. <br />
            Working together with a number of international logistics companies
            with efficient and convenient means of transport, we ensure that our
            products will reach customers in a timely and safe manner. To better
            service our customers worldwide, RiCOMA has set up the Global
            Customer Service Center both in the US and in China, which offers
            uninterrupted service, 24 hours a day with faster response to
            customers’ needs. <br />
            This unparalleled and unprecedented service in the industry helps
            our customers, wherever you are, enjoy the RiCOMA products with ease
            and confidence. We appreciate the trust and support you have placed
            in our company and will continue to work hard to bring our customers
            the competitive advantage you deserve and expect from us.
            <br /> Only when our customers succeed with our products and
            services, can we succeed in our business! This is what we believe
            and what we strive to achieve from day to day.
          </p>
        </div>
        <div className="about__image">
          <img
            src="./images/about/company.jpg"
            alt="RicomaQatar Embroidery Machine Sales and Service"
            className="img-responsive"
          />
        </div>
        <div className="about__culture my-3 container">
          <h2 className="about__title">Company Culture</h2>
          <div className="row about__culture--desc">
            <div className="col-6">
              <img
                src="./images/about/culture.jpg"
                alt="RicomaQatar Embroidery Machine Sales and Service"
                className="img-fluid"
              />
            </div>
            <div className="col-6">
              <h3>Efficiency,</h3>
              <h3>Passion,</h3>
              <h3>Excellence</h3>
            </div>
          </div>
        </div>
        <div className="about__brand mb-4 container">
          <h2 className="about__title">Brand Concept</h2>
          <h2>THINK BEYOND</h2>
          <div className="about__brand--desc">
            <div className="lead about__brand--desc-header">R&D Strength</div>
            <p>
              With customers’ needs in mind at all times, Ricoma has been
              adhering to independent technological innovation and implementing
              brand marketing strategy. From the beginning, we have been
              committed to research, development, design and production of
              computerized embroidery machines and software applications. We
              have an excellent team of qualified engineers with software and
              hardware development capabilities.
            </p>
          </div>
        </div>
        <div className="about--distributor">
          <h2 className="about__title">Authorized Distributors Qatar</h2>
          <img
            src="./images/about/map.jpg"
            alt="RicomaQatar Embroidery Machine Sales and Service"
            className="img-fluid"
          />
        </div>
        <div className="about--certificate py-4">
          <h2 className="about__title">Certificates</h2>
          <div className="about__certificate--images">
            <img
              src="./images/certificate/certificate1.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
            <img
              src="./images/certificate/certificate2.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
            <img
              src="./images/certificate/certificate3.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
            <img
              src="./images/certificate/certificate4.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
            <img
              src="./images/certificate/certificate5.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
            <img
              src="./images/certificate/certificate6.jpg"
              alt="RicomaQatar Embroidery Machine Sales and Service"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
