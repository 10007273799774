import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./HeadTitle.css";

const HeadTitle = () => {
  const location = useLocation();
  const pathId = location.pathname.split("/")[1];

  return (
    <>
      <section className="image-heading">
        <div className="container">
          <h1>{pathId}</h1>

          <button>
            <Link to="/">Home / </Link>
            <span>{pathId}</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default HeadTitle;
