import React, { useEffect } from "react";
import Card from "../common/Products/Card";
import productData from "../common/Products/productData";
import AOS from "aos";

const Prodcuts = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <section className="products pt-5 pb-5">
      <h2 className="title pb-3">Products</h2>
      <div className="container">
        <div className="row">
          {productData.map((product) => {
            const { id, title, images,navlink } = product;
            return (
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-3"
                key={id}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Card id={id} title={title} images={images} navlink={navlink} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Prodcuts;
