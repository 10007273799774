import React,{useContext} from 'react'
//import EmbroideryData from './EmbroideryData';
import HeadTitle from '../HeadTitle/HeadTitle';
import Card from './Card';
import { EmbroideryContext } from '../../Context/EmbroideryContext';

const Embroidery = () => {
  


  const EmbroideryData = useContext(EmbroideryContext);
  console.log(EmbroideryData);


  return (
    <section className="products">
      <HeadTitle />

      <h2 className="text-center mb-5">Products</h2>
      <div className="container">
        <div className="row">
          {EmbroideryData.map((product) => {
            return (
              <div
                className="col-6 col-md-3 col-lg-3  m-auto "
                key={product.id}
              >
                <Card
                  images={product.images}
                  title={product.title}
                  navlink={product.navlink}
                  desc={product.desc}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Embroidery