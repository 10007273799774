import React from "react";
import "./software.css";
import HeadTitle from "../../common/HeadTitle/HeadTitle";

const Software = () => {
  return (
    <>
      <div id="software" className="software">
        <HeadTitle />
        <div className="main">
          <div className="software_main">
            <div className="software-header text-center">
              Chroma Digitizing Software
            </div>
          </div>
          <div className="software_show container text-center">
            <div className="software_showimg">
              <img
                src="./images/software/software1.jpeg"
                alt="chorma software"
              />
            </div>
            <h1>Chroma Digitizing Software</h1>
            <p>
              Chroma,Ricoma's new proprietary software,is designed to allow both
              the novice and experienced digitizer to even the most intricate
              designs with ease and speed.
            </p>
          </div>
          <div className="feature">
            <div className="container">
              <div className="row m-auto gy-4">
                <div className="feature-heading text-center">Features</div>
                {/* Feature-1 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature1.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-2 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature2.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-3 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature3.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-4 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature4.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-5 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature5.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-6 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature6.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* Feature-7 */}
                <div className="col-12">
                  <div className="text-center">
                    <img
                      src="./images/software/software_feature7.jpeg"
                      alt="Chroma Software"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Software;
