import React from 'react'
import { Link } from 'react-router-dom';

const Card = ({images,title,navlink,desc}) => {

  return (
    <div className="card text-center mb-3" >
      <div className="card-body">
        <h3 className="card-title">{title}</h3>
        <img
          src={images}
          alt="Ricoma Qatar Embroidery machine Sales And Service"
          className="img-fluid"
          style={{height:"150px",width:"150px"}}
        />
        <p className='card-text m-2'>
            {desc}
        </p>
        
        <Link to={`/${navlink}`} className="btn btn-primary mt-3">
          {title}
        </Link>
      </div>
    </div>
  );
}

export default Card