import React from "react";
import "./Whatsapp.css";
import {Link} from 'react-router-dom'

const Whatsapp = () => {
  return (
    <>
      <div className="whatsapp">
        <Link
          to="https://api.whatsapp.com/send?phone=+97450907170&text=Hello%20I%20want%20More%20information."
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="./images/whatsapp.png"
            alt="Ricoma Qatar Embroidery Machine Sales and Service"
            className="whatsapp__img img-fluid"
          />
        </Link>
      </div>
    </>
  );
};

export default Whatsapp;
