import "./home.css";
// import Banner from "./Banner";
import About from "./About";
import Hero from "./Hero/Hero";
import Prodcuts from "./Products";
import Services from "./Services";
import Contact from "./ContactForm/Contact";

const Home = () => {
  return (
    <div className="home" id="home">
      {/* <Banner /> */}
      <Hero />
      <About />
      <Prodcuts/>
      <Services />
      <Contact />
    </div>
  );
};

export default Home;
