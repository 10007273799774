import React, { useEffect } from 'react'
import AOS from 'aos'

const About = () => {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div className="about container pt-4 pb-4">
      <h2 className="title">About Us</h2>
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-6" data-aos="fade-right" data-aos-duration="500">
          <img
            src="./images/about/culture.jpg"
            alt="Ricoma Qatar Embroidery Machne Sales and Service"
            className="img-fluid"
          />
        </div>
        <div className="col-6" data-aos="fade-left" data-aos-duration="500">
          <h3>Efficiency,</h3>
          <h3>Passion,</h3>
          <h3>Excellence</h3>
          <h3>THINK BEYOND</h3>
        </div>
      </div>
    </div>
  );
}

export default About