import React from 'react'
import { CardMedia } from "@mui/material";
import { Link } from 'react-router-dom';

const Decorating = () => {
  return (
    <div className="decorate">
      <div className="container">
        <div className="decorate__product row">
          <div className="col-12 col-sm-6 col-md-6">
            <img
              src="./images/software/Decorating/decorating_box_1.png"
              alt="Wilcom"
              className="img-fluid"
            />
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <h2>Wilcom Embroidery Studio Decorating e4</h2>
            <p>
              <strong>SKU</strong> EMBSTU-DEC-E4
            </p>
            <Link to="/software" aria-label="Product">
              Be the first to review this product
            </Link>
            <button type="button" className="btn btn-primary">
              Enquiry Now
            </button>
            <img
              src="./images/software/Decorating/DecoratingLogo_RGB_BLK_SML.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="decorate__overview">
        <button className="btn btn-primary w-100" type="button">
          Overview
        </button>
        <div className="decorate__overview-desc container">
          <h1>Wilcom Embroidery Studio Decorating e4</h1>
          <h3>Professional Embroidery & Multi-Decoration Software.</h3>
          <p>
            The new standard for custom embroidery shops and apparel decorators
            expanding into embroidery. With a simple graphics digitizing
            approach, full control of lettering and stitch settings and over 200
            professional embroidery fonts, you can produce your own commercial
            embroidery designs more quickly and easily than ever before.
          </p>
          <p>
            <strong>CorelDRAW® Graphics Suite</strong> is included, fully
            integrated and provides full vector and bitmap graphics software for
            multi-decoration, printing, cutting, engraving and more.{" "}
            <Link to="/software" aria-label="Read More About">
              Read more about
            </Link>{" "}
            <strong>
              <Link to="/software" aria-label="CorelDraw">
                CorelDRAW®
              </Link>
            </strong>
          </p>
          <p>
            Get more quotes and jobs through your shop by creating and managing
            design digitizing requests with the new Order Job Feature. Quickly
            prepare design mock-ups on high resolution garment images and email
            .PDF approval sheets directly to customers.
          </p>
          <p>
            Use Auto-Digitizing to get accurate stitch estimates from customer
            artwork then save basic order and job production information right
            in the Wilcom .EMB design file for easy reference.
          </p>
          <p>
            Use the new integrated Design Library to find designs in seconds by
            customer, order, date and other text or parameters, all within the
            software.
          </p>
          <p>
            Apply your existing graphics design knowledge to digitize your own
            embroidery designs with the new graphics digitizing tools.
            Compatible with all EmbroideryStudio e4 software products.
          </p>
        </div>
      </div>

      <div className="decorate__feature">
        <button className="btn btn-primary w-100" type="button">
          Features
        </button>
        <div className="container">
          <h1>Features</h1>
          <h3>Advanced Integrated Design Library</h3>
          <ul>
            <li>
              Search designs in seconds by customer, order, date or any text
              field
            </li>
            <li>
              Designs are automatically indexed for fast search. No manual
              categorization required
            </li>
            <li>
              Browse designs from all folders in one screen, or folder by folder
            </li>
            <li>
              Group and sort designs by customer, order, date, folder, design
              status, stitches and more
            </li>
            <li>
              Quickly see recent designs by date, no matter what folder they are
              in
            </li>
            <li>
              Batch operations on multiple selected designs. Open, convert,
              print, stitch and catalogue
            </li>
            <li>Use simple drop lists to search text from a specific field</li>
            <li>Default “General” for the fastest and widest search results</li>
            <li>Search by specified dates or pre-set date ranges.</li>
          </ul>
          <h3>Order Job Feature</h3>
          <ul>
            <li>
              Save customer, order & job details in the .EMB design file. A
              separate database is not required
            </li>
            <li>
              Identifies the garment, quantities, sizes, colors and approval
              status
            </li>
            <li>
              Display job details with high resolution product mock-ups in .PDF
              for customer approval
            </li>
            <li>
              Estimate stitch counts from customer artwork using enhanced
              Auto-Digitizing
            </li>
            <li>
              Create digitizing requests as .EMB designs and manage status in
              the Design Library
            </li>
            <li>
              Minimize printed worksheets by sending .EMB with job details to
              production computer
            </li>
            <li>Specify Color/Size/Quantity in table form</li>
            <li>Customize the information displayed on approval sheets</li>
            <li>Quickly create new colorways with Cycle Used Colors.</li>
          </ul>
          <h3>Enhanced Product Visualizer and Customer Approvals</h3>
          <ul>
            <li>
              Create mock-ups of designs on high resolution garment images in
              the required colors
            </li>
            <li>
              Wide selection of high resolution predefined generic products
              included
            </li>
            <li>Add your own custom product images</li>
            <li>Move and re-size product images within the design</li>
            <li>
              Print new .PDF approval sheets with job details or email directly
              to customers for approval
            </li>
            <li>
              Save and record the product image directly in the .EMB design file
              for future use.
            </li>
          </ul>
          <h3>NEW and Improved Lettering</h3>
          <ul>
            <li>
              25 NEW embroidery fonts including 3D foam, logo fonts and
              specialty fonts
            </li>
            <li>NEW predefined "Lettering Art" envelope shapes</li>
            <li>Easier selection of embroidery fonts by style or type</li>
            <li>Expanded capabilities and easier to use Team Names feature</li>
            <li>Improved quality of automatic TrueType/OpenType stitching.</li>
          </ul>
          <h3>Faster Digitizing for Professionals and Newcomers</h3>
          <ul>
            <li>New graphics digitizing tools for easier digitizing</li>
            <li>
              Create embroidered outlines and fills with the same shape tools
            </li>
            <li>
              Convert outlines to/from fills in a single click with the new
              stitch types toolbar
            </li>
            <li>Add or remove holes and stitch angles</li>
            <li>
              Create drawing objects as vector outlines or fills in the same way
            </li>
            <li>Much faster handling and processing of very large designs.</li>
          </ul>
          <h3>Improved Auto-Digitizing</h3>
          <ul>
            <li>New instant Auto-Digitizing of bitmaps with one click</li>
            <li>Improved interactive Auto-Digitizing dialog</li>
            <li>
              Enhanced quality satin and fill stitching, better optimized for
              logo design
            </li>
            <li>
              Supports full color bitmaps with transparency (24 and 32 bit)
            </li>
            <li>Optional cropping / masking of bitmap artwork images</li>
            <li>Optional bitmap image adjustments before stitching.</li>
          </ul>

          <h3>Enhanced Monogramming</h3>
          <ul>
            <li>
              69 professional predefined monogram designs or create your own
            </li>
            <li>Extensive but easy to use monogramming user interface</li>
            <li>Separate tabs for Designs, Letters, Ornaments and Borders</li>
            <li>Advanced stitching parameters hidden unless asked for</li>
            <li>Use embroidery fonts or TrueType fonts.</li>
          </ul>
          <h3>More efficient design workspace</h3>
          <ul>
            <li>4K monitor support, large or small icons, optional labels</li>
            <li>
              Improved arrangement of toolbox tools with quick?access flyouts
            </li>
            <li>
              Switch between multiple open designs effortlessly with new design
              tabs
            </li>
            <li>
              Visualize stitching sequence with the new Stitch Player using
              classic media player controls
            </li>
            <li>
              New Color toolbar commands and My Threads docker for easier color
              management
            </li>
            <li>Large, full featured Open dialog displays design details</li>
            <li>"Open Recent Designs" with thumbnail view.</li>
          </ul>

          <h3>7 add-on Elements Included Now as STANDARD</h3>
          <ul>
            <li>
              Virtual Decoration - Create high resolution embroidery TrueView
              images for digital printing.
            </li>
            <li>
              Vector Drawing - Create and edit vector graphics directly in
              Wilcom designs.
            </li>
            <li>
              Bling - Create and output bling designs for stones, rhinestones,
              beads and spangles.
            </li>
            <li>
              Auto-Digitize Bitmaps - One click converts bitmap graphics into
              embroidery.
            </li>
            <li>
              Kiosk Mode - Ultra simple personalised designs for retail shops.
            </li>
            <li>
              Team Names Standard – Create lettering for multiple team members
              in one design
            </li>
            <li>
              Offset Standard – Create automatic outlines around selected
              objects or designs
            </li>
          </ul>

          <h3>NEW Drag-and-drop .EMB Designs via Clipart Docker</h3>
          <ul>
            <li>Use .EMB designs from any source</li>
            <li>Search for designs or select from specific folders.</li>
          </ul>

          <h3>LATEST Version e4.1 .EMB Format</h3>
          <ul>
            <li>
              Keep up to date with the latest version e4.1 EMB file format
            </li>
            <li>Reads all previous .EMB versions and exports versions.</li>
          </ul>

          <h3>Latest CorelDRAW® Graphics Suite 2017</h3>
          <ul>
            <li>Bundled with NEW e4.1 systems</li>
            <li>
              Optional upgrade from X8.2 available for EmbroideryStudio e4.0
              customers
            </li>
            <li>
              Open CorelDRAW .CDR graphic files directly from the
              EmbroideryStudio Design Library.
            </li>
          </ul>
        </div>
      </div>

      <div className="decorate__videos">
        <button className="btn btn-primary w-100" type="button">
          Videos
        </button>
        <div className="container">
          <h2>EmbroideryStudio e4 – Update e4.1</h2>
          <p>
            Update e4.1 for EmbroideryStudio e4 is packed with new features and
            enhancements. We have created a brief overview to a selection to
            give you a taste of what you could look to experience. Please note:
            The videos will display if the feature is available as standard or
            as a paid add-on Element as well as which versions of
            EmbroideryStudio e4 they may be used.
          </p>
          <div className="youtube_video text-center">
            <CardMedia
              component="iframe"
              src="https://www.youtube.com/embed/QPQQEJFMrqo"
              sx={{
                aspectRatio: "16/9",
              }}
            />
          </div>
        </div>
      </div>

      <div className="decorate__why">
        <button className="btn btn-primary w-100">Why Updates?</button>

        <div className="container">
          <h2>Why Update to EmbroideryStudio e4?</h2>
          <p>
            EmbroideryStudio e4 takes a major leap forward from the previous e3
            version. With a strong focus on improving both the creative side of
            the software as well as business productivity through improvements
            in efficiency.
          </p>
          <Link to="/software" aria-label="Wilcom Embroidery Studio">
            What’s new in EmbroideryStudio e4?
          </Link>
          <p>
            There are several brand new tools and features packed into the
            software as well as improvements to many of the existing
            professional tools. It also now includes 7 features which previously
            were additional add-on Elements that were available to buy
            separately.
          </p>
          <h3>Our favorite reasons to update to e4 Decorating:</h3>
          <ul>
            <li>
              <h4>New Design Library</h4>
              <p>
                Powerful design management capabilities with high speed search
                for customer designs, all within the software. Find by customer,
                order, date or any design information. This feature replaces the
                aged Design Explorer.
              </p>
            </li>
            <li>
              <h4>Order Job Feature</h4>
              <p>
                Save customer, order & job details directly into the .EMB design
                file. Create design workflows and manage design requests,
                estimates, customer approvals and production worksheets all
                within the software. A separate database is not required.
              </p>
            </li>
            <li>
              <h4>CorelDRAW® Graphics Suite 2017 INCLUDED</h4>
              <p>
                Fully integrated and bundled within the software. There are many
                new and improved Corel features specifically relevant to
                embroidery, multi-decoration, printing, cutting and engraving.
                It includes an extensive clipart and font library. Easy to
                install and Windows 10 compatible.
              </p>
            </li>
            <li>
              <h4>Enhanced Product Visualizer</h4>
              <p>
                New high-resolution garment images can be used to create
                accurate order mock-ups. Resize, re-color and save directly into
                the .EMB design file. Print new PDF approval sheets with all the
                job details or email them directly to customers.
              </p>
            </li>
            <li>
              <h4>New and Improved Lettering</h4>
              <p>
                The world class Wilcom lettering got even better plus there are
                25 NEW professionally digitized fonts too! Now with Unicode
                support and OpenType Glyphs for fancy lettering. New 3D foam,
                outline, running stitch and two color fonts. There is also an
                improved font management and Team Names feature.
              </p>
            </li>
            <li>
              <h4>New Graphics Style Digitizing</h4>
              <p>
                Easier digitizing perfect for beginners yet with the full
                control over inputs for more advanced users. Create embroidered
                outlines and fills using the same shape tool. Convert outlines
                to/from fills in a single click with the new Stitch toolbar. Add
                or remove holes and stitch angles. Create drawing objects as
                vector outlines or fills in the same way.
              </p>
            </li>
            <li>
              <h4>Improved Auto-Digitizing</h4>
              <p>
                Greatly improved image processing and vector auto-digitizing
                capabilities for commercial use. Generate automatic stitch
                counts for quotations and job estimates. Simple logo designs can
                be auto-digitized and edited instead of outsourcing.
              </p>
            </li>
            <li>
              <h4>Enhanced Monogramming</h4>
              <p>
                New easy to use monogramming interface with 69 professional
                predefined monogram designs, or create your own. Take greater
                control of your monogram letter sizing, spacing and stitching.
              </p>
            </li>
            <li>
              <h4>More Efficient Design Workspace</h4>
              <p>
                Switch between multiple open designs effortlessly with the new
                design tabs. An improved arrangement of toolbox tools with
                quick-access flyouts helps you work faster. Visualize stitching
                sequences with the new Stitch Player using classic media player
                controls. New Color toolbar commands and My Threads docker for
                easier color management. Support for 4K monitors with optional
                large or small icons.
              </p>
            </li>
            <li>
              <h4>e3 Elements now included as Standard in e4 Decorating</h4>
              <p>
                There are 7 new features included which were previously add-on
                Elements purchase separately. This includes Offsets Standard,
                Team Names Standard, Vector Drawing, Auto-Digitize Bitmaps,
                Bling, Kiosk Mode and Virtual Decoration.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="system">
        <button className="btn btn-primary w-100" type="button">
          System Requirements
        </button>
        <div className="container">
          <h2>PC Specifications for EmbroideryStudio e4</h2>
          <p>
            Please ensure you have the correct hardware to ensure the software
            runs well. EmbroideryStudio e4 is compatible with Windows 7, 8 and
            10 operating systems. If you prefer, you can also run the software
            on a Mac.
          </p>
          <p>
            <strong>Note:</strong> An internet connection is required for
            software updates and dongle registration.
          </p>
          <p>
            The following are the minimum and recommended system requirements
            for EmbroideryStudio e4.
          </p>
          <h3>Minimum Requirements</h3>
          <ul>
            <li>CPU - Intel® Core i3 or AMD Athlon™ 64</li>
            <li>
              Operating System – Windows 7 32-bit with latest updates and
              service pack
            </li>
            <li>Browser – Microsoft Edge or IE11.0</li>
            <li>Memory – 4 GB</li>
            <li>Free Hard Disk Space – 40 GB</li>
            <li>
              Graphics Card and Monitor – Support for 32bit and 1600 x 900
              resolution
            </li>
            <li>USB Ports – 2 free for installation (3 for upgrades)</li>
            <li>Sound Card – Required for multimedia training</li>
            <li>
              Internet Connection - Required for software updates and dongle
              registration
            </li>
          </ul>
          <h3>Recommended</h3>
          <ul>
            <li>CPU – Latest Intel® Core i7</li>
            <li>
              Operating System – Windows 10 64-bit with latest updates and
              service pack
            </li>
            <li>Browser – Microsoft Edge or later</li>
            <li>Memory – 8 GB</li>
            <li>Free Hard Disk Space – 256 GB or more (Solid State Drive)</li>
            <li>
              Graphics Card and Monitor – Support for Highest Color 32bit and
              1920 x 1080 resolution or higher
            </li>
            <li>USB ports - 2 free for installation (3upgrades)</li>
            <li>Sound Card - Required for multimedia training</li>
            <li>Backup - Extrernal hard drive, cloud storage or other.</li>
            <li>
              intenet connection - Required for software updates and dongle
              registration
            </li>
          </ul>
        </div>
      </div>

      <div className="realted">
        <button className="btn btn-primary w-100" type="button">
          Related Products
        </button>
        <div className="container p-5">
          <div className="row g-3">
            <div className="col-4">
              <div className="card" style={{ width: "auto", padding: "15px" }}>
                <img
                  src="./images/software/Designing/designing.png"
                  alt="wilcom"
                  className="img-fluid"
                />
                <h4 className="p-3">Wilcom Embroidery Studio Designing e4</h4>
                <Link
                  to="/software/lettering"
                  className="btn btn-primary"
                  type="button"
                  aria-label="Enquiry Now"
                >
                  Enquiry Now
                </Link>
              </div>
            </div>

            <div className="col-4">
              <div className="card" style={{ width: "auto", padding: "15px" }}>
                <img
                  src="./images/software/Designing/designing.png"
                  alt="wilcom"
                  className="img-fluid"
                />
                <h4 className="p-3">Wilcom Embroidery Studio Designing e4</h4>
                <Link
                  to="/software/editing"
                  className="btn btn-primary"
                  type="button"
                  aria-label="Enquiry Now"
                >
                  Enquiry Now
                </Link>
              </div>
            </div>

            <div className="col-4">
              <div className="card" style={{ width: "auto", padding: "15px" }}>
                <img
                  src="./images/software/Designing/designing.png"
                  alt="wilcom"
                  className="img-fluid"
                />
                <h4 className="p-3">Wilcom Embroidery Studio Designing e4</h4>
                <Link
                  to="/software/designing"
                  className="btn btn-primary"
                  type="button"
                  aria-label="Enquiry Now"
                >
                  Enquiry Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decorating;