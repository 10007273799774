import React from 'react';
import './service.css';
import HeadTitle from '../../common/HeadTitle/HeadTitle';
import HomeIcon from '@mui/icons-material/Home';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';

const Service = () => {
  return (
    <>
      <HeadTitle />
      <section className="service" id="service">
        <div className="service_header">
          <h1>Service</h1>
        </div>
        <div className="container">
          <div className="text-center pb-4">
            <h3>All Embroidery Machine Spare Parts and Services</h3>
          </div>
          <img
            src="./images/services/service.jpg"
            alt="Ricoma embroidery Machine Service"
            className="img-fluid w-100 p-4"
          />
          <div className="row gy-4 pt-4">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="service-address">
                <div className="get-in-touch">
                  <h3>Contact</h3>
                  <div className="get-in-touch-wrapper">
                    <div className="icon-logo">
                      <HomeIcon />
                      <PhoneInTalkIcon />
                      <WhatsAppIcon />
                      <AttachEmailIcon />
                    </div>
                    <div className="contact-desc">
                      <span>Doha,Qatar</span>
                      <span>+974 5090 7170</span>
                      <span>+974 5090 7170</span>
                      <span>ricoma.qatar@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
